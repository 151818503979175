import React from "react";
import { ApolloProvider } from "@apollo/client";

// import { Provider as ReduxProvider } from "react-redux"
import { FormProvider } from "@helpers/hooks/formContext";
import { apollo } from "./clients";
// import { createReduxStore } from "./redux/store"

export const wrapRootElement: React.FC = ({ element }) => (
  <FormProvider>
    <ApolloProvider client={apollo}>{element}</ApolloProvider>
  </FormProvider>
);

// export default ({ element }) => (
//   <ReduxProvider store={createReduxStore()}>{element}</ReduxProvider>
// )
