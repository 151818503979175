/**
 * Copyright 2021 Design Barn Inc.
 */

import { ApolloCache, Resolvers as BaseResolvers } from "@apollo/client";

export type ResolverFn = (
  parent: any,
  args: any,
  cache: { cache: ApolloCache<any> }
) => any;

export interface ResolverMap {
  [field: string]: ResolverFn;
}

export interface Resolvers extends BaseResolvers {
  Mutation: ResolverMap;
  Query: ResolverMap;
}

export type CreateResolversFn = () => Resolvers;

export const createResolvers: CreateResolversFn = () => {
  return {
    Query: {
      notionMKBPlayers: async () => {
        try {
          const response = await fetch(
            `https://mk-badminton.corplabs.co/databases/8546d3b9d65f4fcf985dbc3e077d5027/query`,
            {
              method: "POST",
              body: JSON.stringify({
                sorts: [
                  {
                    property: "Handicap",
                    direction: "ascending",
                  },
                ],
              }),
            }
          );

          const data = await response.json();

          return { data };
        } catch (err) {
          console.log(err);
        }
      },
      notionMKBList: async () => {
        try {
          const response = await fetch(
            `https://mk-badminton.corplabs.co/databases/1f599914aeb6449f8c6d4d47f1e83c8e/query`,
            {
              method: "POST",
              body: JSON.stringify({
                sorts: [
                  {
                    property: "Date",
                    direction: "descending",
                  },
                ],
              }),
            }
          );

          const data = await response.json();

          return { data };
        } catch (err) {
          console.log(err);
        }
      },
      mkbAction: async () => {
        try {
          const response = await fetch(
            `https://mk-badminton.corplabs.co/databases/9d2c6c498cb541f8a774b47ceb7d0ee9/query`,
            {
              method: "POST",
            }
          );

          const data = await response.json();

          return { data };
        } catch (err) {
          console.log(err);
        }
      },
      mkbCredit: async () => {
        try {
          const response = await fetch(
            `https://mk-badminton.corplabs.co/databases/7f6d80103f2f483195e6280a93de57f6/query`,
            {
              method: "POST",
              body: JSON.stringify({
                page_size: 20,
                sorts: [
                  {
                    property: "Date",
                    direction: "descending",
                  },
                ],
              }),
            }
          );

          const data = await response.json();

          return { data };
        } catch (err) {
          console.log(err);
        }
      },
      mkbPlayerCredit: async () => {
        try {
          const data = await getPlayerCredits();

          return { data };
        } catch (err) {
          console.log(err);
        }
      },
    },
    Mutation: {
      createMatch: async (_, { input, userAgent, addedBy }) => {
        try {
          const response = await fetch(
            "https://mk-badminton.corplabs.co/pages", // Adjust URL if needed
            {
              method: "POST",
              body: JSON.stringify({
                parent: {
                  database_id: "1f599914aeb6449f8c6d4d47f1e83c8e",
                },
                properties: {
                  Action: {
                    title: [{ text: { content: "actions" } }],
                  },
                  "User Agent": {
                    type: "rich_text",
                    rich_text: [
                      {
                        type: "text",
                        text: { content: userAgent },
                      },
                    ],
                  },
                  AddedBy: {
                    type: "rich_text",
                    rich_text: [
                      {
                        type: "text",
                        text: { content: addedBy },
                      },
                    ],
                  },
                  PW1: {
                    relation: [{ id: input.wp1 }],
                  },
                  PW2: {
                    relation: [{ id: input.wp2 }],
                  },
                  PL1: {
                    relation: [{ id: input.lp1 }],
                  },
                  PL2: {
                    relation: [{ id: input.lp2 }],
                  },
                  Date: {
                    date: { start: input.todayDate },
                  },
                  "Loser Score": { number: Number(input.loserScore) },
                },
              }),
              headers: {
                "Content-Type": "application/json",
                // Add any required headers (e.g., authentication)
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }

          const data = await response.json();

          // Handle potential errors in the response data (if applicable)
          if (data.error) {
            throw new Error(`API error: ${data.error}`);
          }

          return { data };
        } catch (err) {
          console.error("Error creating MKB player:", err);
          throw err; // Re-throw for potential UI error handling
        }
      },
      createCredit: async (_, { input, addedBy }) => {
        try {
          const response = await fetch(
            "https://mk-badminton.corplabs.co/pages", // Adjust URL if needed
            {
              method: "POST",
              body: JSON.stringify({
                parent: {
                  database_id: "7f6d80103f2f483195e6280a93de57f6",
                },
                properties: {
                  Manipulation: {
                    title: [{ text: { content: "actions" } }],
                  },
                  Player: {
                    relation: [{ id: input.player }],
                  },
                  Actions: {
                    relation: [{ id: input.action }],
                  },
                  Amount: {
                    number: Number(input.amount),
                  },
                  Date: {
                    date: { start: input.todayDate },
                  },
                  AddedBy: {
                    type: "rich_text",
                    rich_text: [
                      {
                        type: "text",
                        text: { content: addedBy },
                      },
                    ],
                  },
                },
              }),
              headers: {
                "Content-Type": "application/json",
                // Add any required headers (e.g., authentication)
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }

          const data = await response.json();

          // Handle potential errors in the response data (if applicable)
          if (data.error) {
            throw new Error(`API error: ${data.error}`);
          }

          return { data };
        } catch (err) {
          console.error("Error creating MKB player:", err);
          throw err; // Re-throw for potential UI error handling
        }
      },
      patchPages: async (_, { input }) => {
        console.log("pageID");
        console.log(input);

        try {
          const response = await fetch(
            `https://mk-badminton.corplabs.co/pages/${input}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                archived: true,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }

          const data = await response.json();

          // Handle potential errors in the response data (if applicable)
          if (data.error) {
            throw new Error(`API error: ${data.error}`);
          }

          return { data };
        } catch (err) {
          console.error("Error creating MKB player:", err);
          throw err; // Re-throw for potential UI error handling
        }
      },
    },
  };
};

async function queryDatabase(start_cursor = undefined) {
  const response = await fetch(
    "https://mk-badminton.corplabs.co/databases/7f6d80103f2f483195e6280a93de57f6/query",
    {
      method: "POST",
      body: JSON.stringify({
        start_cursor,
      }),
    }
  );
  return response.json();
}

function getPropertyValue(page, propertyName) {
  const prop = page.properties[propertyName];
  if (prop.type === "number") {
    return prop.number || 0;
  } else if (prop.type === "relation" && prop.relation.length > 0) {
    return prop.relation[0].id;
  } else if (
    prop.type === "rollup" &&
    prop.rollup.type === "array" &&
    prop.rollup.array.length > 0
  ) {
    return prop.rollup.array[0].title[0].plain_text;
  } else if (prop.type === "select" && prop.select) {
    return prop.select.name;
  }
  return null;
}

async function getPlayerCredits() {
  const players = {};
  let hasMore = true;
  let nextCursor = undefined;

  while (hasMore) {
    const data = await queryDatabase(nextCursor);
    for (const page of data.results) {
      const playerName = getPropertyValue(page, "name");
      const amount = getPropertyValue(page, "Amount");
      const actionName = getPropertyValue(page, "action");
      const id = getPropertyValue(page, "Player");

      if (playerName && amount !== null && actionName) {
        if (!players[playerName]) {
          players[playerName] = {
            name: playerName,
            total: 0,
            count: 0,
            basketball: 0,
            penalty: 0,
            contribution: 0,
            id: id,
          };
        }
        players[playerName].total += amount;

        // Update contribution for all actions except 'minus credit' and 'add credit'
        if (
          actionName.toLowerCase() === "basketball" ||
          actionName.toLowerCase() === "badminton"
        ) {
          players[playerName].contribution += amount;
        }
        if (actionName.toLowerCase() === "badminton") {
          players[playerName].count += 1;
        }

        if (actionName.toLowerCase() === "basketball") {
          players[playerName].basketball += amount;
        } else if (actionName.toLowerCase() === "penalty") {
          players[playerName].penalty += amount;
        }
      }
    }

    hasMore = data.has_more;
    nextCursor = data.next_cursor;
  }

  return Object.values(players).sort((a, b) => b.total - a.total);
}
