import React, { createContext, useContext, useState } from "react";

const FormContext = createContext(null);
interface FormProviderProps {
  children: React.ReactNode;
  location: string;
}
export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [formValues, setFormValues] = useState({});
  return (
    <FormContext.Provider value={{ formValues, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = (): any => useContext(FormContext);
